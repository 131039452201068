import { Link } from "gatsby";
import React, { PropsWithChildren, ReactElement } from "react";
import { dateHandler, handleSplit } from "../../../shared/helpers";
import { HeroScheduleProps, IMosoClasses } from "./HeroInterfaces";
import styles from "./HeroSchedule.module.scss";


const Hero = (props: PropsWithChildren<HeroScheduleProps>): ReactElement => {
    const { img, title, classes, tryClass, alt } = props;

    let currentDate: Date;

    let currentProps;

    let startDateSplitted: string = '';

    let endDateSplitted: string = '';

    let nextClasses: Array<IMosoClasses> = []

    classes?.sort((a: any, b: any) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime()) //order classes for date

    //check for classes > this moment
    classes?.forEach((elementClass: IMosoClasses) => {
        if (new Date(elementClass.startDate).getTime() > new Date().getTime()) nextClasses.push(elementClass);
    })

    // if we have , set current class with the next available class
    if (nextClasses && nextClasses.length > 0) {
        currentDate = new Date(nextClasses[0].startDate);
        currentProps = dateHandler(currentDate, nextClasses[0]);
        startDateSplitted = new Date(nextClasses[0]?.startDate).toLocaleTimeString("en-US", { timeZone: process.env.TIME_ZONE });
        endDateSplitted = new Date(nextClasses[0]?.endDate).toLocaleTimeString("en-US", { timeZone: process.env.TIME_ZONE });
    }
    return (
        <div
            className={`${styles.heroMedia}`}
        >
            <img src={img} alt={alt} className={styles.img} />

            <div className={styles.alignedLeft}>

                {title && <h1>{title}</h1>}

                {currentProps && <div>

                    <h3 className={styles.nextClassTitle}>Next Class:</h3>

                    <div className={styles.nextClassWrapper}>

                        <div className={styles.blockInfoWrapper}>
                            <h4>
                                <span>{currentProps?.numberDate.split("/")[1]} </span>
                                {currentProps?.monthName.short}
                            </h4>
                            {startDateSplitted && endDateSplitted && <p>{handleSplit(startDateSplitted)} - {handleSplit(endDateSplitted)}</p>}
                        </div>
                        <div className={styles.blockInfoWrapper}>
                            <h4>Where</h4>
                            <p>{nextClasses[0]?.location}</p>
                        </div>
                        <div className={styles.blockInfoWrapper}>
                            <h4>With</h4>
                            <p>{nextClasses[0]?.instructor?.toLocaleLowerCase()}</p>
                        </div>
                    </div>

                    <div className={styles.buttonContainer}>
                        <Link to={'/try-us'} state={{ classSelected: tryClass?.name }} className={styles.tryButton}>Try class</Link>
                    </div>

                </div>

                }

            </div>
        </div>
    );
};

export default Hero;
