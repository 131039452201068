import React, {
    PropsWithChildren,
    ReactElement,
    useState,
    useEffect,
} from "react";
import Helmet from "../../components/Helmet";
import LocationInstructor from "./LocationInstructor";
import Schedule from "../../components/shared/Schedule";
import Hero from "./Hero";
import TextBanner from "../../components/Widgets/TextBanner";
import GradientImageBanner from "../../components/Widgets/GradientImageBanner";
import useWindowWidth, {
    heroDesktopMinWidth,
} from "../../hooks/useWindowWidth";
import WarningComponent from "../../components/shared/FallbackWarnComponent";
import { getSeoDescription, getSeoKeywords, getSeoTitle } from "../../shared/helpers";

interface PageProps {
    pageContext: any;
}

const ClassesTemplate = (props: PropsWithChildren<PageProps>): ReactElement => {
    const isDesktop = useWindowWidth(heroDesktopMinWidth);
    const { data, relations } = props.pageContext;
    const { try_us } = relations;

    let info = {
        banner_text: data.about_text,
        banner_title: data.about_title,
        banner_cta_text: "",
        banner_cta_url: "",
    };

    let infoWarn = {
        banner_text: "",
        banner_title: "",
        banner_cta_text: "",
        banner_cta_url: "",
    };

    const bannerProps = {
        banner_title: try_us.dataRaw?.title,
        banner_text: try_us.dataRaw?.subtitle,
        banner_background: try_us.dataRaw?.background_image,
        banner_cta_text: try_us.dataRaw?.cta_text,
        banner_cta_url: try_us.dataRaw?.cta_url,
        banner_gradient_color: try_us.dataRaw?.grandient_color,
        banner_text_color: try_us.dataRaw?.text_color,
    };

    const [backgroundRef, setBackground] = useState(data.class_img.url);
    const [backgroundAlt, setBackgroundAlt] = useState(data.class_img.alt);


    useEffect(() => {
        setBackground(isDesktop ? data.class_img.url : data.mobile_img?.url);
        setBackgroundAlt(isDesktop ? data.class_img.alt : data.mobile_img?.alt);
    }, [isDesktop]);

    return (
        <>
            <Helmet
                title={getSeoTitle(data, "Class")}
                description={getSeoDescription(data, "Class page")}
                keywords={getSeoKeywords(data, "")}
            />

            {backgroundRef ? (<Hero
                img={backgroundRef}
                alt={backgroundAlt}
                title={data.page_title[0].text}
                classes={props.pageContext.integrationData.classes}
                tryClass={data.class}
            />) : (
                <WarningComponent
                    template={`Class ${data.page_title[0]?.text}`}
                    message={"Hero image"}
                    fallbackWidget={<Hero
                    img={'/assets/image/ClassPlaceHolder.jpg'}
                    alt={'Classes Studio'}
                    title={data.page_title[0].text}
                    classes={props.pageContext.integrationData.classes}
                    tryClass={data.class}
                />}
                />
            )}

            {data.about_text.length ? (
                <TextBanner data={info} />
            ) : (
                <WarningComponent
                    template={`Class ${data.page_title[0]?.text}`}
                    message={"About data"}
                />
            )}

            {data.class ? (
                <LocationInstructor {...data.class} />
            ) : (
                <WarningComponent
                    template={`Class ${data.page_title[0]?.text}`}
                    message={"Location / Instructors"}
                />
            )}

            <Schedule classes={props.pageContext.integrationData.classes} />

            {try_us.dataRaw ? (
                <GradientImageBanner data={{ ...bannerProps }} />
            ) : (
                <WarningComponent
                    template={`Class ${data.page_title[0]?.text}`}
                    message={"Try us widget"}
                />
            )}
        </>
    );
};

export default ClassesTemplate;
