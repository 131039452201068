import React, { PropsWithChildren } from "react";
import { Link } from "gatsby";
import style from "./card.module.scss";
interface CardProps {
    uid: any;
    title: any;
    studio?: any;
    image?: any;
    svg?: any;
    location: boolean;
}
function Card(props: PropsWithChildren<CardProps>) {
    const { image, title, studio, uid, svg, location } = props;

    return (
        <div className={`${image ? style.grid : style.hasText}`}>
            {image && (
                <div className={style.imgContainer}>
                    <img src={image} alt={title} className={style.fill} />
                </div>
            )}

            <div className={style.titleWrapper}>
                <h4 className={style.title}>
                    {title} {studio ? "-" : ""}{" "}
                    {studio && <span> {studio}</span>}
                </h4>
            </div>
            {location ? (
                <Link to={uid} className={style.link}>
                    More about this location{" "}
                </Link>
            ) : (
                <Link to={`/scheduler?roleId=${uid}`} className={style.link}>
                    View all of{" "}
                    <span className={style.instructorName}>
                        {title.split(",")[1]}
                    </span>{" "}
                    classes{" "}
                </Link>
            )}
        </div>
    );
}

export default Card;
