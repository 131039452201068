import React, { PropsWithChildren, ReactElement } from "react";
import { dateHandler, handleSplit } from "../../../../shared/helpers"
import { IMosoClasses } from "../ScheduleInterfaces";
import styles from "./Card.module.scss";

const Card = (props: PropsWithChildren<IMosoClasses>): ReactElement => {
    const { endDate, startDate, instructor, location, className, shortDescription } = props;
    const currentDate = new Date(startDate);
    const currentProps = dateHandler(currentDate, props);
    const startDateSplitted = startDate ? new Date(startDate).toLocaleTimeString("en-US", { timeZone: process.env.TIME_ZONE }) : null ;
    const endDateSplitted = endDate ? new Date(endDate).toLocaleTimeString("en-US", { timeZone: process.env.TIME_ZONE }) : null;
    const { numberDate, monthName } = currentProps;
    let cutDate = numberDate.split("/")[1];


    return (
        <div className={styles.card}>
            <span className={styles.shortDescription}>{shortDescription}</span>
            <h1 className={styles.date}>
                {cutDate}
                <span>{monthName.short}</span>
            </h1>

            <h2 className={styles.dateTitle}>
                {className}
            </h2>

            { startDate && endDate && <div className={styles.infoBlock}>
                <h3>WHEN</h3>
                <span>
                    {handleSplit(startDateSplitted)} - {handleSplit(endDateSplitted)}
                </span>
            </div>}

            {location && <div className={styles.infoBlock}>
                <h3>WHERE</h3>
                <span>{location}</span>
            </div>}

            {instructor && <div className={styles.infoBlock}>
                <h3>WITH</h3>
                <span className={styles.trainerName}>{instructor.toLocaleLowerCase()}</span>
            </div>}
        </div>
    );
};

export default Card;
