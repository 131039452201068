import React, { PropsWithChildren, ReactElement } from "react";
import { instructorPlaceHolderIcon } from "../../../shared/icons";
import Card from "./Card"
import style from "./location-instructor.module.scss"
interface LocationInstructorProps {
    instructors: any,
    locations: any

}

const LocationInstructor = (
    props: PropsWithChildren<LocationInstructorProps>
): ReactElement => {
    const { instructors, locations } = props

    return (
        (instructors?.length  > 0 || locations?.length > 0 )? <section className={`${style.cardWrapper} mainBox`}>
            {locations && locations.length > 0 && <div className={style.locations}>
                <h3>Locations:</h3>
                {locations.map((location: any, i: number) => {
                     if (location.location === null) return;
                    return <Card key={i} title={location.location} image={location.locationImg?.url} uid={location.slug} location={true} />
                })}
            </div>}
            {instructors && instructors.length > 0 && <div className={style.instructors}>
                <h3>Instructors:</h3>
                {instructors.map((instructor: any, i: number) => {
                    if (instructor.instructor === null) return;
                    return <Card key={i} title={instructor.instructor.toLowerCase()} uid={instructor.roleId} svg={instructorPlaceHolderIcon} location={false} />
                })}
            </div>}
        </section> : <></>
    );
};


export default LocationInstructor;
