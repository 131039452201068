import React, { PropsWithChildren, ReactElement } from "react";
import { Link } from "gatsby";
import styles from "./TextBanner.module.scss";

interface TextBannerProps
{
    data: any;
}

const TextBanner = (props: PropsWithChildren<TextBannerProps>): ReactElement =>
{
    const {
        banner_cta_text,
        banner_cta_url,
        banner_text,
        banner_title,
    } = props.data;
    return (
        <section
            className={styles.textBanner}
        >
            {banner_title[0] && <h2>{banner_title[0]?.text}</h2>}
            {banner_text.length > 0 && banner_text.map((x: any, i: number) => <p key={i} className={'mainBox'}>{x.text}</p>)}

            {banner_cta_text && (
                <Link to={`/${banner_cta_url[0]?.text}`}>
                    <span>{banner_cta_text[0]?.text}</span>
                </Link>
            )}
        </section>
    );
};

export default TextBanner;
